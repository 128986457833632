<template>
    <div class="py-4 h-full flex flex-col items-center text-black">

        <h1 class="font-light text-xs self-end mr-3 mb-6 font-montserrat uppercase">
            <b class="rounded-full bg-black py-1 px-3 text-white">Score: {{ this.score }}</b>
        </h1>
        <h1 data-aos="fade-up" class="font-montserrat text-3xl text-center uppercase px-10 font-bold">Quiz <span class="text-primary">Time</span></h1>

		<p class="text-center font-light text-sm text-primary max-w-xs mt-1 mb-6">Try to get as many correct answers as you can!</p>

        <transition name="question-fade" mode="out-in">
            <div v-if="refresh" class="mx-2 xs:mx-6">
                <h1 class="px-2 mb-6 text-xl text-center" data-aos="fade-up" data-aos-delay="200">{{ question.title }}</h1>
                <ul class="flex flex-col px-2 justify-center items-center">
                    <li data-aos="fade-right" :data-aos-delay="300*(index+1)" v-for="(answer, index) in answers" @click="select(answer, $event)" class="bg-white text-primary shadow-2xl p-4 relative w-full my-1 rounded-full" :key="index">
                        <span class="font-light pr-3">{{ letters[index] }}.</span>
                        {{ answer.title }}  
                        <div class="absolute text-xs top-0 mt-5 mr-5 opacity-75 text-yellow right-0" v-if="answer.is_correct == 1 && hostname == 'localhost'">correct</div>
                    </li>
                </ul>
            </div>
        </transition>

        <div class="flex flex-col w-full mt-auto px-6">
            <h4 class="uppercase text-xs text-black font-light ml-auto">Time</h4>
            <div class="border border-secondary h-3 w-full">
                <div class="bg-secondary h-full" :style="{ transition: 'linear all 1s', width: progressBar + '%' }"></div>
            </div>
        </div>

        <transition name="component-fade" mode="out-in">
            <div class="overlayres" v-if="timesup">
                <h2 class="uppercase bg-primary font-bold text-3xl py-4 px-12 shadow-lg">Times Up!</h2>
            </div>
        </transition>

        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-top sm h-52" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-top sm h-36" src="../../assets/images/triangle@2x.png" alt="">
	
	</div>
</template>

<script>
export default {
    
    data(){
		return{
            question: Object,
            answers: Array,
            letters: ['A','B','C','D'],
            result: null,
            cookieAnswered: window.Cookies.getJSON('quiz-answered') ? window.Cookies.getJSON('quiz-answered') : [],
            score: 0,
            time: 0,
            lastQuestionTime: 0,
            progressBar: 0,
            timesup: false,
            whatmatch: false,
            loading: false,
            refresh: true,
            window: window.shared,
            hostname: window.location.hostname
		}
    },
    
    beforeMount()
    {
        //window.Cookies.set('quiz-answered', []);
        
        var params = {
            //answered: window.Cookies.getJSON('quiz-answered')
            answered: this.cookieAnswered
        };

        this.$api.get('questions', params).then(r => {
            this.question = r.data
   			this.answers = r.data.answers
   			this.loading = false
        });

    },

    mounted(){

        /*
        this.$api.post('query/log/action', {
            uid: window.Shared.user.id,
            action: 'play',
            name: 'quiz'
        });
        */
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/quiz/play"
        })

        let seconds = 15;
        let int = setInterval(() => {
            let progress = 100 / seconds;
            this.time = this.time + 1
            this.lastQuestionTime = this.lastQuestionTime + 1
            this.progressBar = this.time * progress;

            if(this.progressBar >= 99){
                clearInterval(int);
                setTimeout(() => this.timesup = true, 1000)
                setTimeout(() => {

                    this.$emit('upcomponent', 'thanks', { 
                        game: 'Quiz', 
                        score: this.score 
                    });
                    
                    /*
                    this.$api.post('query/leaderboard/post_score', { id: window.Shared.user.id, score: this.score,duration: this.time, origin: 'Quiz', token: window.Shared.user.agorize_id * (Date.now() / 1000) }).then(r => {
                        this.$emit('upcomponent', 'play.score', { 
                            game: 'Quiz', 
                            score: this.score 
                        });
                    })
                    */

                }, 2000)
            }

        }, 1000);

    },

    methods: {
        select(answer, ev){

            var is_correct;
            
            if(answer.is_correct == 1){
                ev.target.classList.add('bg-green-300');
                is_correct = true
                
                if(this.lastQuestionTime < 10){
                    this.score = this.score + 50
                } else {
                    this.score = this.score + 25
                }
                
                this.result = 'Correct!'

			} else {
				ev.target.classList.add('bg-red-300');
                this.result = 'Wrong!'
                is_correct = false
			}
            
            var $answered = this.cookieAnswered
			$answered.push(this.question.id)
            window.Cookies.set('quiz-answered', $answered);

            if($answered.length > 25){
                window.Cookies.set('quiz-answered', []);
            }

            /*** log ***/
            /*
            this.$api.post('query/log/quiz', {
                uid: window.Shared.user.id,
                question_id: this.question.id,
                answer_id: answer.id,
                is_correct: is_correct
            });
            */
            /***/
            
            setTimeout(() => {
                
                this.result = null
                this.refresh = false
                
                ev.target.classList.remove('bg-green-300');
                ev.target.classList.remove('bg-red-300');

                var params = {
                    answered: window.Cookies.getJSON('quiz-answered')
                };

                this.$api.get('questions', params).then(r => {
                    
                    this.question = r.data
   			        this.answers = r.data.answers
                    this.refresh = true
                    this.lastQuestionTime = 0

                    console.log('Answered:' + $answered.length);

                });    

            }, 500)

        }
    }
}
</script>