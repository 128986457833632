<template>
    <div class="h-full w-full flex flex-col items-center justify-center" :class="data.poll">
        
        <h1 class="font-montserrat z-20 text-2xl mb-2 text-white text-center uppercase px-10 mt-5 font-bold" v-if="data.poll == 'poll-a'">{{ $t('heroes') }}</h1>
        <h1 class="font-montserrat z-20 text-2xl mb-2 text-white text-center uppercase px-10 mt-5 font-bold" v-else>{{ $t('ihof') }}</h1>

        <p v-if="data.poll == 'poll-a'" class="text-center text-white px-4">{{ $t('poll_a') }}</p>
        <p v-else class="text-center text-white px-4">{{ $t('desc') }}</p>

        <ul data-aos="zoom-in" data-aos-delay="200" class="w-10/12 mt-5 z-20">
            <li v-for="i in 4" class="lb-item" :class="{ selected: i == 2 }">
                <span class="bg-primary border-white border text-white h-8 w-8 rounded-full flex items-center justify-center mr-2"><i class="fas fa-trophy"></i></span>
                {{ i == 2 ? data.form.name : getName() }}
                <span class="ml-auto font-bold">{{ 750-(50*i) }}</span>
            </li>
        </ul>

        <button @click="goTo('intro')" class="bg-dark rounded-full font-light px-12 mx-auto z-20 py-4 mt-6 text-white">{{ $t('finish') }}</button>

    </div>
</template>

<style scoped>
.lb-item{
    @apply rounded-full font-light w-full flex items-center shadow-2xl my-2 p-3 bg-white;
}

.lb-item.selected{
    @apply text-white bg-primary;
}
</style>

<style lang="scss" scoped>
.poll-a{
    background-image: url(../assets/images/bg-poll-s-a@2x.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
}

.poll-b{
    background-image: url(../assets/images/bg-poll-s-b@2x.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
}
</style>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				heroes: 'Our Environmental Heroes',
                ihof: 'Innovation Hall of Fame',
                poll_a: 'Here are the biggest donors in this campaign – thank you for helping us to make an impact!',
                desc: 'We always want to improve our products and appreciate your input – here are our greatest innovators!',
                finish: 'Finish Experience'
			},
			de: { 
				heroes: 'Unsere Umwelt-Heroes',
                ihof: 'Hall of Fame der Innovation',
                poll_a: 'Hier sind die größten Spender dieser Kampagne - Vielen Dank für Ihre Unterstützung durch die wir zusammen einen Unterschied machen ',
                desc: 'Wir wollen unsere Produkte ständig verbessern und freuen uns über Ihre Anregungen - hier sind unsere größten Innovatoren!',
                finish: 'Experience beenden'
			},
			ru: { 
				heroes: 'Наши Экологические Герои',
                ihof: 'Инновация Hall of Fame',
                poll_a: 'Вот самые крупные спонсоры этой кампании - Спасибо Вам, что помогли нам оказать влияние',
                desc: 'Мы всегда улучшаем свои продукты и ценим Ваш вклад - вот наши главные инноваторы',
                finish: 'Закончить Опыт '
			}
		}
	},
    props: ['data'],
    methods: {
        goTo(c){
            this.$emit('upcomponent', c, { poll: this.data.poll });
        },
        getName(){
            var fName = ["Andrew", "Adam", "Seth", "Nicolle", "Eric", "Alexander", "Sophia", "Bill", "Maria", "John", "Anna", "James", "Nikolas", "Elon", "Hellen"];
            var lName = ["A.", "S.", "L.", "M.", "P.", "Z.", "R."];

            var randomPositionA = Math.floor(Math.random() * fName.length);
            var randomPositionB = Math.floor(Math.random() * lName.length);
            
            return fName[randomPositionA] + ' ' + lName[randomPositionB];
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/leaderboard"
        })
    }
}
</script>