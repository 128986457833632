<template>
    <div class="overlay">

        <div class="w-10/12 rounded-lg text-center py-12 px-6 mx-auto" :class="poll">
            <h1 class="font-montserrat font-bold text-3xl uppercase">{{ $t('thanks') }}</h1>
            
            <p v-if="poll == 'poll-a'" class="font-montserrat py-5">{{ $t('poll_a') }}</p>
            <p v-if="poll == 'poll-b'" class="font-montserrat py-5">{{ $t('poll_b') }}</p>
            <p v-if="poll == 'poll-c'" class="font-montserrat py-5">{{ $t('poll_c') }}</p>

            <button @click="goTo" class="bg-dark rounded-full font-light px-12 mx-auto z-20 py-3 mt-6 text-white">{{ $t('continue') }}</button>
        </div>

    </div>
</template>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				thanks: 'Thank You',
				poll_a: 'In order to complete the donation on your behalf please share your contact and we will update you on the results of the campaign!',
                poll_b: 'Please share your contacts and we’ll send you a sample of the new flavour before everyone else!',
                poll_c: 'Please leave your contact to know more about our product and packaging innovation!',
                continue: 'Continue'
			},
			de: { 
				thanks: 'DANKESCHÖN',
				poll_a: 'Um die Spende zu vervollständigen, teilen Sie uns bitte Ihre Kontaktdaten mit sodass wir Sie über die Ergebnisse der Kampagne auf dem Laufenden halten können!',
                poll_b: 'Bitte teilen Sie uns Ihre Kontaktdaten mit, damit wir Ihnen vor allen anderen eine Probe des neuen Geschmacks zusenden können!',
                poll_c: 'Bitte hinterlassen Sie Ihre Kontaktdaten, um mehr über unsere Produkt- und Verpackungsinnovation zu erfahren!',
                continue: 'Weiter',
			},
			ru: { 
				thanks: 'СПАСИБО',
				poll_a: 'Для того чтобы продолжить оставьте свой контакт и мы сообщим Вам результаты кампании.',
                poll_b: 'Пожалуйста, поделитесь своими контактами и мы отправим Вам образец нового вкуса раньше всех!',
                poll_c: 'Пожалуйста, оставьте свой контакт, чтобы узнать больше о наших продуктах и инновациях в упаковке!',
                continue: 'Продолжить',
			}
		}
	},
    props: ['poll'],
    methods: {
        goTo(){
            this.$emit('upcomponent')
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay{
    background: rgba(0,0,0,.8);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 123;
    display: flex;
    justify-content: center;
    align-items: center;
    .poll-a{
        background-image: url(../assets/images/bg-thanks-a@2x.png);
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
    }
    .poll-b{
        background-image: url(../assets/images/bg-thanks-b@2x.png);
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
    }
    .poll-c{
        background-image: url(../assets/images/bg-thanks-a@2x.png);
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
        color: white;
    }
}
</style>