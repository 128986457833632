<template>
    <div class="py-4 h-full flex flex-col items-center text-white bg-a">

        <h1 xdata-aos="fade-up" class="font-montserrat text-2xl text-center uppercase pt-10 px-5 font-bold">
            {{ $t('title') }}
        </h1>
        
        <div class="flex flex-col items-center">
            <p class="font-light w-10/12 text-center" v-html="$t('desc')"></p>
            <img src="../../assets/images/icon-world@2x.png" class="h-16 a-spin my-4">
            <nav class="flex flex-col w-10/12">
                <button @click="getResult" class="rounded-full text-base px-8 w-full shadow-2xl h-20 flex justify-center items-center bg-green">
                    {{ $t('opt_1') }}
                    <img src="../../assets/images/env-poll-a@2x.png" class="h-24 absolute left-0 ml-4">
                </button>
                <button @click="getResult" class="rounded-full text-base px-8 my-4 w-full shadow-2xl h-20 flex justify-center items-center bg-green">
                    {{ $t('opt_2') }}
                    <img src="../../assets/images/env-poll-b@2x.png" class="h-16 absolute right-0 mr-4 -mt-6">
                </button>
                <button @click="getResult" class="rounded-full text-base px-8 w-full shadow-2xl h-20 flex justify-center items-center bg-green">
                    {{ $t('opt_3') }}
                    <img src="../../assets/images/env-poll-c@2x.png" class="h-16 absolute left-0 ml-2 -mt-6">
                </button>
            </nav>
        </div> 

        <transition name="component-fade" mode="out-in">
            <thanks v-if="result" poll="poll-a" @upcomponent="goTo" />
        </transition>

    </div>
</template>

<script>
import thanks from '../../components/thanks'

export default {
	i18n: {
		messages: {
			en: { 
				title: 'Environmental Poll',
				desc: 'Thank you for purchasing our product! <br> Help us make a positive impact on our planet by choosing a simple action you would like us to do on your behalf',
                opt_1: 'Plant a tree on my behalf!',
                opt_2: 'Donate one cent to save endangered species!',
                opt_3: 'Donate one cent to secure safe drinkable water in Africa',
			},
			de: { 
				title: 'Ökologische Befragung',
				desc: 'Danke, dass Sie unser Produkt gekauft haben! Helfen Sie uns, einen positiven Einfluss auf unseren Planeten zu nehmen, indem Sie eine einfache Aktion wählen, die wir in Ihrem Namen durchführen sollen',
                opt_1: 'Pflanzen Sie einen Baum in meinem Namen!',
                opt_2: 'Spenden Sie einen Cent zur Rettung bedrohter Arten!',
                opt_3: 'Spenden Sie einen Cent, um Trinkwasser in Afrika zu sichern!',
			},
			ru: { 
				title: 'Экологический опрос',
				desc: 'Спасибо за покупку нашего продукта! Помогите нам оказать положительное влияние на нашу планету, выбрав простое действие, которое Вы хотели бы, чтобы мы совершили за Вас',
                opt_1: 'Посадить дерево',
                opt_2: 'Пожертвовать один цент, чтобы спасти исчезающие виды',
                opt_3: 'Пожертвовать один цент на сохранение питьевой воды в Африке',
			}
		}
	},
    data(){
        return{
            result: false
        }
    },
    components: {
        thanks
    },
    methods: {
        getResult(){
            this.result = true
        },
        goTo(){
            this.$emit('upcomponent', 'thanks', { poll: 'poll-a' })
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/poll/play"
        })
    }
}
</script>

<style lang="scss" scoped>
.bg-a{
    background-color: #6A9D24;
}

.a-spin{
    animation: spinA 20s linear infinite;

    @keyframes spinA {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }
}
</style>