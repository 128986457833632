<template>
    <div class="h-full w-full bg-light flex flex-col items-center justify-center">
        
        <img data-aos="zoom-in" data-aos-delay="600" src="../assets/images/logo-blue-stamp@2x.png" class="h-16 z-20" alt="">
        <p data-aos="fade-up" data-aos-delay="300" class="text-white font-montserrat font-semibold px-6 mx-auto text-center mt-2 z-20">
            {{ $t('choose') }}
        </p>

        <nav data-aos="zoom-in" data-aos-delay="600" class="w-11/12 flex flex-col mt-5 z-10">
            <button @click="goTo('poll.intro')" class="rounded-3xl text-lg w-full mb-2 shadow-2xl p-3 text-primary bg-white">{{ $t('menu_a') }}</button>
            <button @click="goTo('quiz.intro')" class="rounded-3xl text-lg w-full shadow-2xl p-3 text-primary bg-white">{{ $t('menu_b') }}</button>
            <button @click="goTo('game.intro')" class="rounded-3xl text-lg w-full my-2 shadow-2xl p-3 text-primary bg-white">{{ $t('menu_c') }}</button>
            <button @click="goTo('selfie')" class="rounded-3xl text-lg w-full shadow-2xl p-3 text-primary bg-white">{{ $t('menu_d') }}</button>
            <button @click="goTo('package.intro')" class="rounded-3xl text-lg w-full mt-2 shadow-2xl p-3 text-primary bg-white">{{ $t('menu_e') }}</button>            
        </nav>

        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000" class="circle-top h-72" src="../assets/images/circle@2x.png" alt="">
        <img class="triangle-top filter-white h-36" src="../assets/images/triangle@2x.png" alt="">
        
        <img data-aos="fade-up" data-aos-delay="1000" data-aos-duration="2000" class="circle-bottom h-72" src="../assets/images/circle@2x.png" alt="">
        <img class="triangle-bottom filter-white h-64" src="../assets/images/triangle-b@2x.png" alt="">

    </div>
</template>

<script>
import axios from 'axios';

export default {
	i18n: {
		messages: {
			en: { 
				choose: 'Choose the connected package experience that will be more valuable to your consumers!',
                menu_a: 'Environmental Poll',
                menu_b: 'Product Innovation Poll',
                menu_c: 'Play a Game',
                menu_d: 'Take a Selfie',
                menu_e: 'Explore Package'
			},
			de: { 
				choose: 'Wählen Sie die Connected Package Experience, die für Ihre Konsumenten am geeignetsten ist!',
                menu_a: 'Ökologische Befragung',
                menu_b: 'Umfrage zum Thema Produktinnovationen',
                menu_c: 'Spiele spielen',
                menu_d: 'Selfies machen',
                menu_e: 'Verpackung erkunden'
			},
			ru: { 
				choose: 'Выберите наиболее ценный Connected Package Experience для Ваших потребителей',
                menu_a: 'Экологический Опрос',
                menu_b: 'Опрос по Инновационным Продуктам',
                menu_c: 'Играть в Игру',
                menu_d: 'Сделать Селфи',
                menu_e: 'Изучить Продукт'
			}
		}
	},
    methods: {
        goTo(c){
            this.$emit('upcomponent', c)
        }
    },
    mounted(){

        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/intro"
        })
        
        axios.post('https://dashboard.good-day-win.ch/api/tetrapak/geo').then(r => {

            let latlon = r.data.lat + ',' + r.data.lon;

            this.$api.post('log/action', {
                sessid: window.Shared.user.sessid,
                action: 'view',
                name: 'intro',
                latlon: latlon
            });

        });

    }
}
</script>