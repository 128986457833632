<template>
    <div class="h-full w-full text-white flex flex-col items-center justify-center" :class="data.poll">
        
        <h1 data-aos="fade-up" class="font-montserrat z-20 text-3xl text-center uppercase px-10 mt-5 font-bold">Demo Experience <span class="font-extrabold">Completed</span></h1>
        <p data-aos="fade-up" data-aos-delay="300" class="text-base mt-6 mx-6 text-center">You’ll receive an email shortly with access to the admin dashboard. View stats, results, and data collected for analysis!</p>

        <button @click="goTo('intro')" class="bg-dark rounded-full font-light px-12 mx-auto z-20 py-3 mt-6 text-white">Back To Home</button>

    </div>
</template>

<style lang="scss" scoped>
.poll-a{
    background-image: url(../assets/images/bg-poll-s-a@2x.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
}

.poll-b{
    background-image: url(../assets/images/bg-poll-s-b@2x.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
}
</style>

<script>
export default {
    props: ['data'],
    methods: {
        goTo(c){
            this.$emit('upcomponent', c)
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/finish"
        })
    }
}
</script>