<template>
    <div class="h-full w-full flex flex-col items-center justify-center" :class="data.poll">

        <p dxata-aos="fade-up" data-aos-delay="300" class="font-bold text-lg text-white text-base text-center px-4 mt-2 font-montserrat">{{ $t('fill') }}</p>
        
        <form action="" @submit.prevent="submitForm" class="relative flex flex-col justify-center items-center z-20 mt-2 w-10/12">

            <input class="input focus:outline-none" type="text" v-model="form.name" placeholder="Name">
            <input class="input focus:outline-none" type="email" v-model="form.email" placeholder="E-mail">
            <select class="input focus:outline-none text-gray-400">
                <option value="">{{ $t('select_gender') }}</option>
                <option value="female">{{ $t('female') }}</option>
                <option value="male" selected>{{ $t('male') }}</option>
            </select>
            <select class="input focus:outline-none text-gray-400">
                <option value="">{{ $t('age_range') }}</option>
                <option value="18-24">18-24</option>
                <option value="25-36" selected>25-36</option>
                <option value="37-45">37-45</option>
                <option value="46-54">46-54</option>
                <option value="55-64">55-64</option>
                <option value="64+">64+</option>
            </select>
            <select class="input focus:outline-none text-gray-400">
                <option value="">{{ $t('select_retailer') }}</option>
                <option value="mercadona" selected>{{ $t('retailer_a') }}</option>
                <option value="lidl">{{ $t('retailer_b') }}</option>
                <option value="lidl">{{ $t('retailer_c') }}</option>
                <option value="lidl">{{ $t('retailer_d') }}</option>
            </select>

            <button type="submit" class="bg-dark focus:outline-none rounded-full font-light px-20 mx-auto py-3 mt-4 text-white">{{ $t('continue') }}</button>

        </form>

        <p class="text-white mt-6 font-montserrat">{{ $t('subs_share') }}</p>
        <nav class="flex mt-1">
            <a href="https://www.facebook.com/sharer/sharer.php?u=https://connectedpackage.digital/" target="_blank" class="bg-dark text-white h-10 w-10 text-xl flex justify-center items-center rounded-full"><i class="fab fa-facebook-f"></i></a>
            <a href="http://www.twitter.com/share?url=https://connectedpackage.digital/" target="_blank" class="bg-dark text-white mx-1 h-10 w-10 text-xl flex justify-center items-center rounded-full"><i class="fab fa-twitter"></i></a>
            <a href="whatsapp://send?text=Tetrapak Demo Experience https://connectedpackage.digital/" class="bg-dark text-white h-10 w-10 text-xl flex justify-center items-center rounded-full"><i class="fab fa-whatsapp"></i></a>
        </nav>

        <transition name="component-slide" mode="out-in">
            <div v-if="error" class="bg-red-900 w-full text-center py-3 fixed top-0 left-0 text-white font-montserrat z-10">{{ error }}</div>
        </transition>

    </div>
</template>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				fill: 'Please fill in your data',
				select_gender: 'Select Gender',
                male: 'Male',
                female: 'Female',
                age_range: 'Age Range',
                select_retailer: 'Select Retailer',
                retailer_a: 'Mercadona',
                retailer_b: 'Lidl',
                retailer_c: 'Carrefour',
                retailer_d: 'Amazon Prime Now',
                continue: 'Continue',
                subs_share: 'Subscribe or Share through'
			},
			de: { 
				fill: 'Bitte geben Sie Ihre Kontaktdaten ein',
				select_gender: 'Select Gender',
                male: 'Männlich',
                female: 'Weiblich',
                age_range: 'Alter wählen',
                select_retailer: 'Händler auswählen',
                retailer_a: 'Edeka',
                retailer_b: 'Rewe',
                retailer_c: 'Lidl',
                retailer_d: 'Aldi',
                continue: 'Weiter',
                subs_share: 'Abonnieren oder Teilen durch'
			},
			ru: { 
				fill: 'Пожалуйста, заполните Ваши данные',
				select_gender: 'Выберите Пол',
                female: 'Женщина',
                male: 'Мужчина',
                age_range: 'Выберите Возраст',
                select_retailer: 'Выберите Розничного Продавца',
                retailer_a: 'Лента',
                retailer_b: 'Спар',
                retailer_c: 'Ашан',
                retailer_d: 'Амазон',
                continue: 'Продолжить',
                subs_share: 'Подписаться или поделиться через'
			}
		}
	},
    props: ['data'],
    data(){
        return{
            error: false,
            form: {
                name: 'John Doe',
                email: 'johndoe@tetrapak.com'
            }
        }
    },
    mounted(){
        console.log(this.data.shareScreen)  
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/thanks"
        })
    },
    methods: {
        submitForm(){

            this.error = false

            if(this.form.name == ''){
                this.error = 'Fill in your name'
                return
            }

            if(this.form.email == ''){
                this.error = 'Fill in your e-mail'
                return
            }

            if(this.data.shareScreen == true){
                this.$emit('upcomponent', 'share');
                return;
            }

            if(this.data.poll == 'poll-c'){
                this.$emit('upcomponent', 'intro', { poll: this.data.poll, form: this.form });
            } else {
                this.$emit('upcomponent', 'leaderboard', { poll: this.data.poll, form: this.form });
            }

        }
    }
}
</script>

<style>
.input{
    @apply bg-white rounded-full w-full shadow-2xl my-1 p-4;
}

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}
</style>

<style lang="scss" scoped>
.poll-a,
.poll-c{
    background-image: url(../assets/images/bg-poll-s-a@2x.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
}

.poll-b{
    background-image: url(../assets/images/bg-poll-s-b@2x.png);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat;
}
</style>