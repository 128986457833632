<template>
	<div id="app" class="h-screen w-screen bg-gray-300" v-if="portrait == true && mobile == true">
		
		<div id="holder" class="relative w-full h-full" :class="{ nonav: dynamicComponent == 'selfie' || dynamicComponent == 'game.play' }">
			<transition name="component-fade" mode="out-in">
				<component :is="dynamicComponent" :data="data" @upcomponent="upcomponent"></component>
			</transition>
		</div>

		<transition name="component-slide" mode="out-in">
			<nav v-if="dynamicComponent != 'selfie' && dynamicComponent != 'game.play'" class="fixed bottom-0 left-0 bg-white w-full py-4 z-30">
				<button @click="upcomponent('intro')" class="w-1/6 focus:outline-none">
					<i class="fas fa-home" :class="{ 'text-primary opacity-100': dynamicComponent == 'intro' }"></i>
				</button>
				<button @click="upcomponent('poll.intro')" class="w-1/6 focus:outline-none">
					<i class="fas fa-tree" :class="{ 'text-primary opacity-100': dynamicComponent == 'poll.intro' }"></i>
				</button>
				<button @click="upcomponent('quiz.intro')" class="w-1/6 focus:outline-none">
					<i class="fas fa-lightbulb" :class="{ 'text-primary opacity-100': dynamicComponent == 'quiz.intro' }"></i>
				</button>
				<button @click="upcomponent('game.intro')" class="w-1/6 focus:outline-none">
					<i class="fas fa-gamepad" :class="{ 'text-primary opacity-100': dynamicComponent == 'game.intro' }"></i>
				</button>
				<button @click="upcomponent('selfie')" class="w-1/6 focus:outline-none">
					<i class="fas fa-portrait" :class="{ 'text-primary opacity-100': dynamicComponent == 'selfie' }"></i>
				</button>
				<button @click="upcomponent('package.intro')" class="w-1/6 focus:outline-none">
					<img src="@/assets/images/icon-tp-package@2x.png" class="h-5 mx-auto relative" style="top:3px">
				</button>
			</nav>
		</transition>

	</div>
	<div class="text-primary bg-gray-300 flex flex-col h-screen w-screen items-center justify-center" v-else>
		
		<h1 data-aos="fade-up" class="font-montserrat z-20 text-black text-3xl text-center uppercase px-10 mt-5 font-bold" v-html="$t('welcome')"></h1>

		<h1 v-if="!mobile" class="font-montserrat text-black font-bold text-3xl">{{ $t('mobile_only') }}</h1>
		<h1 v-else class="text-4xl">{{ $t('pls_rotate') }}</h1>

		<img v-if="!mobile" src="https://query.appetitecreative.com/v2/public/qr?url=https://connectedpackage.digital/" class="w-56 mt-4" alt="">
		<p v-if="!mobile" class="uppercase opacity-50 font-light mt-5 text-center text-sm">{{ $t('switch_mobile') }}</p>

        <img data-aos="fade-up" data-aos-delay="1000" data-aos-duration="2000" class="circle-bottom h-72" src="./assets/images/circle@2x.png" alt="">
        <img class="triangle-bottom h-64" src="./assets/images/triangle-b@2x.png" alt="">

	</div>
</template>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				welcome: 'Welcome to the <span class="text-primary">demo</span> experience',
				mobile_only: 'Mobile Only',
				pls_rotate: 'Please Rotate Device',
				switch_mobile: 'Switch to mobile and start the experience!',
			},
			de: { 
				welcome: 'Willkommen zu <span class="text-primary">demo</span> experience',
				mobile_only: 'Nur Smartphones',
				pls_rotate: 'Bitte drehen Sie das Gerät ',
				switch_mobile: 'Wechseln Sie zum Smartphone um die Experience zu starten',
			},
			ru: { 
				welcome: 'Добро Пожаловать в <span class="text-primary">Демо</span> Опыт',
				mobile_only: 'Только для мобильных устройств',
				pls_rotate: 'Пожалуйста, вращайте устройство',
				switch_mobile: 'Переключитесь на мобильное устройство и начните играть!',
			}
		}
	},
	data() {
    	return {
      		dynamicComponent: 'welcome',
			data: '',
			mobile: true,
			portrait: true
    	}
	},
  	mounted() {

      	this.onResize();
    
    	let vh = window.innerHeight * 0.01;
    	document.documentElement.style.setProperty('--vh', `${vh}px`)

    	window.addEventListener('resize', () => {
      		let vh = window.innerHeight * 0.01;
			  document.documentElement.style.setProperty('--vh', `${vh}px`);
			  this.onResize();
		});

        if(!Cookies.get('demo-experience-session')) {
   			var session_id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
   		    Cookies.set('demo-experience-session', session_id);
        }

		window.Shared.user.sessid = Cookies.get('demo-experience-session');

  	},
  	methods: {
    	upcomponent(name, arr = null) {
        	this.dynamicComponent = name
        	this.data = arr
		},
		onResize(){
			
			if(window.innerWidth > window.innerHeight){
				this.portrait = false;
			} else {
				this.portrait = true;
			}

			if(window.innerWidth > 767){
				this.mobile = false;
			} else {
				this.mobile = true;
			}

		},
  	}
}
</script>