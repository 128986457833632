<template>
    <div>

        <header class="relative pt-4 flex z-30">
            <h1 class="font-light text-xs ml-3 mr-3 font-montserrat uppercase">
                <b class="rounded-full bg-black py-1 px-3 text-white">{{ $t('score') }}: {{ score }}</b>
            </h1> 
            <nav class="flex items-center mr-auto">
                <span :class="lives >= 1 ? 'opacity-100' : 'opacity-25'" class="bg-white opacity-25 h-3 w-3 rounded-full"></span>
                <span :class="lives >= 2 ? 'opacity-100' : 'opacity-25'" class="bg-white opacity-25 h-3 w-3 mx-1 rounded-full"></span>
                <span :class="lives >= 3 ? 'opacity-100' : 'opacity-25'" class="bg-white opacity-25 h-3 w-3 rounded-full"></span>
            </nav>
            <i @click="goTo('intro')" class="text-white mr-3 fas fa-arrow-left"></i>
        </header>
        
        <transition name="popup-fade">
            <h1 v-if="msg" class="game-msg" v-html="msg"></h1>
        </transition>
        
        <game :details="details" v-on:update-score="onUpdateScore" />

    </div>
</template>

<script>
import game from './game'

export default {
	i18n: {
		messages: {
			en: { 
                score: 'Score',
				gameinst: 'TAP TO CATCH AS MANY ITEMS AS YOU CAN <br> <span>DOUBLE TAP TO JUMP HIGHER</span>',
			},
			de: { 
                score: 'Punktzahl',
				gameinst: 'Tippen Sie, um so viele Gegenstände wie möglich zu fangen <br> <span>Tippen Sie doppelt, um höher zu springen</span>',
			},
			ru: { 
                score: 'Баллы',
				gameinst: 'Нажмите, чтобы поймать как можно больше предметов <br> <span>Нажмите дважды, чтобы прыгнуть выше</span>',
			}
		}
	},
    components: { game },
    data(){
        return{
            lives: 3,
            score: 0,
            gameover: false,
            play: true,
            details: [],
            msg: null
        }
    },
    mounted(){
        this.onMsgUpdate(this.$t('gameinst'), 3000);
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/game/play"
        })
    },
    methods: {
        onUpdateScore(o){
            this.lives = o.lives
            this.score = o.score
            this.gameover = o.gameover
            if(this.gameover){ 
                this.play = false;
                this.$emit('upcomponent', 'thanks', { 
                    game: 'Game', 
                    score: this.score,
                    poll: 'bg-light',
                    shareScreen: true
                });
            }
            if(o.msg){
                this.onMsgUpdate(o.msg)
            }
        },
        onMsgUpdate(msg, timeout = 2000){
            this.msg = msg
            setTimeout(() => {
                this.msg = null
            }, timeout);
        },
        goTo(c){
            this.$emit('upcomponent', c)
        }
    }
}
</script>