<template>
    <div class="h-full bg-secondary w-full flex flex-col items-center justify-center bg-a">

        <b class="absolute top-0 text-white text-xl right-0 m-3 z-10" @click="result=true">
            <i class="fa fa-times"></i>
        </b>

        <iframe :src="`/fake-ar/${srcFrame}.php?lang=${$i18n.locale}`" width="100%" height="100%" scrolling="no" class="absolute top-0 left-0" frameborder="0"></iframe>

        <nav class="absolute z-10 left-0 top-0 mt-3">
            <button @click="tab=1;srcFrame='milk'" :class="{ 'bg-white text-primary': tab == 1 }" class="border focus:outline-none border-white text-white px-4 py-1 text-xs ml-3">{{ $t('milk') }}</button>
            <button @click="tab=2;srcFrame='juice'" :class="{ 'bg-white text-primary': tab == 2 }" class="border focus:outline-none border-white text-white px-4 py-1 text-xs mx-3">{{ $t('juice') }}</button>
            <button @click="tab=3;srcFrame='tomatoes'" :class="{ 'bg-white text-primary': tab == 3 }" class="border focus:outline-none border-white text-white px-4 py-1 text-xs">{{ $t('tomatoes') }}</button>
        </nav>

        <transition name="component-fade" mode="out-in">
            <thanks v-if="result" poll="poll-c" @upcomponent="goTo" />
        </transition>

    </div>
</template>

<script>
import thanks from '../../components/thanks'

export default {
	i18n: {
		messages: {
			en: { 
				milk: "Milk",
                juice: "Juice",
                tomatoes: "Tomatoes"
			},
			de: { 
				milk: "Milch",
                juice: "Saft",
                tomatoes: "Tomaten"
			},
			ru: { 
				milk: "Молоко",
                juice: "Сок",
                tomatoes: "Помидоры"
			}
		}
	},
    data(){
        return{
            tab: 1,
            srcFrame: 'milk',
            result: false,
            hotspot: '',
            showVideo: false
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/ar/experience"
        })
    },
    components: { thanks },
    methods: {
        goTo(){
            this.$emit('upcomponent', 'thanks', { poll: 'poll-c' })
        }
    }
}
</script>