<template>
    <div class="h-full w-full bg-light text-white flex flex-col items-center justify-center">
        
        <h1 data-aos="fade-up" class="font-montserrat text-3xl text-center uppercase mt-10 z-20 px-4 font-bold" v-html="$t('welcome')"></h1>
        <img src="@/assets/images/icon-tp-package@2x.png" class="h-12 filter-invert mt-2">
        
        <p class="px-10 text-center font-montserrat my-5">{{ $t('desc') }}</p>

        <nav data-aos="zoom-in" data-aos-delay="600" class="w-9/12 z-10">
            <button @click="start" class="rounded-full text-xl w-full shadow-2xl p-4 bg-black">{{ $t('start') }}</button>
        </nav>

        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-top h-72" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-top filter-white h-48" src="../../assets/images/triangle@2x.png" alt="">
        
        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-bottom h-72" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-bottom filter-white h-64" src="../../assets/images/triangle-b@2x.png" alt="">

    </div>
</template>

<script>
export default {
    i18n: {
		messages: {
			en: { 
				welcome: 'Welcome to the <span class="text-primary text-2xl">AR EXPERIENCE</span>',
				desc: 'Using AR technology, your consumers can explore your product and learn more!',
                start: "Let's Play"
			},
			de: { 
				welcome: 'Willkommen bei der <span class="text-primary text-2xl">AR-Experience</span>',
				desc: 'Durch den Einsatz von AR-Technologie können Ihre Kunden Ihr Produkt erkunden und mehr darüber erfahren!',
                start: "Los geht’s"
			},
			ru: { 
				welcome: 'Добро пожаловать в <br><span class="text-primary text-2xl">AR EXPERIENCE</span>',
				desc: 'Используя технологию AR, Ваши потребители смогут узнать больше о Вашем продукте!',
                start: "Играть"
			}
		}
	},
    methods: {
        start(){
            this.$emit('upcomponent', 'package.index')
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/ar/intro"
        })
    }
}
</script>

<style>

</style>