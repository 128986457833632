<template>
    <div class="h-full w-full bg-black">

        <div class="absolute text-right z-20 top-0 right-0 text-white p-2">
            <i @click="goTo('intro')" class="fas fa-arrow-left"></i>
        </div>

        <canvas class="deepar" id="deepar-canvas" oncontextmenu="event.preventDefault()"></canvas>
        <div class="absolute loading top-0 left-0 z-10 h-full w-full flex items-center justify-center">
            <loading />
        </div>

        <div class="bg-to-dark absolute z-30 bottom-0 w-screen">
            <div class="glide">
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides h-24">
                        <li @click="goSlide(index)" class="glide__slide transition duration-300 flex justify-center items-center" v-for="(filter,index) in effects" :key="filter.name">
                            <div class="rounded-full bg-cover w-12 h-12" :style="{ backgroundImage: `url(${filter.background})` }"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Glide from '@glidejs/glide'
import loading from '../../components/loading.vue';

export default {
  components: { loading },
    data(){
        return{
            deepAR: null,
            glide: null,
            effects: [
                {
                    background: require('../../assets/images/thumbs/aviators.png'),
                    name: 'aviators',
                    path: './effects/aviators.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/scuba.png'),
                    name: 'scuba',
                    path: './effects/scuba.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/pumpkin.png'),
                    name: 'pumpkin',
                    path: './effects/pumpkin.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/ball_face.png'),
                    name: 'ball_face',
                    path: './effects/ball_face.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/alien.png'),
                    name: 'alien',
                    path: './effects/alien.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/beard.png'),
                    name: 'beard',
                    path: './effects/beard.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/dalmatian.png'),
                    name: 'dalmatian',
                    path: './effects/dalmatian.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/flowers.png'),
                    name: 'flowers',
                    path: './effects/flowers.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/koala.png'),
                    name: 'koala',
                    path: './effects/koala.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/lion.png'),
                    name: 'lion',
                    path: './effects/lion.wasm'
                },
                {
                    background: require('../../assets/images/thumbs/teddy_cigar.png'),
                    name: 'teddy_cigar',
                    path: './effects/teddycigar.wasm'
                }
            ]
        }
    },

    methods: {
        goSlide(index){

            if(this.glide.index == index){
                this.deepAR.takeScreenshot();
            }

            this.glide.go('='+index);
        },
        goTo(c, d = {}){
            this.$emit('upcomponent', c, d)
        }
    },

    beforeDestroy(){
        this.deepAR.stopVideo();
        this.deepAR.shutdown();
    },

    mounted(){

        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/selfies"
        })

        let $this = this;
        
        var canvasHeight = window.innerHeight;
        var canvasWidth = window.innerWidth;
      
        // desktop, the width of the canvas is 0.66 * window height and on mobile it's fullscreen
        if (window.innerWidth > window.innerHeight) {
            canvasWidth = Math.floor(window.innerHeight*0.66);
        }

        $this.deepAR = DeepAR({ 
            canvasWidth: canvasWidth, 
            canvasHeight: canvasHeight,
            licenseKey: '24002aaa8ea6ac825899f184e055a1ed1522a86254d3cf9d8c8315f1eaa3ffad556b217c4819dcf1',
            canvas: document.getElementById('deepar-canvas'),
            numberOfFaces: 1,
            libPath: './lib',
            segmentationInfoZip: 'segmentation.zip',
            onInitialize: function() {
            // start video immediately after the initalization, mirror = true
                $this.deepAR.startVideo(true);

            // or we can setup the video element externally and call deepAR.setVideoElement (see startExternalVideo function below)

                $this.deepAR.switchEffect(0, 'slot', './effects/aviators.wasm', function() {
                    // effect loaded
                });
            }
        });

        $this.deepAR.onCameraPermissionAsked = function() {
            console.log('camera permission asked');
        };

        $this.deepAR.onCameraPermissionGranted = function() {
            console.log('camera permission granted');
        };

        $this.deepAR.onCameraPermissionDenied = function() {
            console.log('camera permission denied');
        };

        $this.deepAR.onScreenshotTaken = function(photo){
            console.log(photo) //data:image/png;base64

            $this.goTo('selfie.preview', { photo: photo })

            //$this.goTo('thanks');
        };

        $this.deepAR.onImageVisibilityChanged = function(visible) {
            console.log('image visible', visible);
        };

        $this.deepAR.onFaceVisibilityChanged = function(visible) {
            console.log('face visible', visible);
        };

        $this.deepAR.onVideoStarted = function() {
            var loaderWrapper = document.querySelector('.loading');
            loaderWrapper.style.display = 'none';
        };

        $this.deepAR.downloadFaceTrackingModel('./lib/models-68-extreme.bin');

        $this.glide = new Glide('.glide', {
            type: 'carousel',
            startAt: 0,
            perView: 3,
            perTouch: 1,
            focusAt: 'center'
        })

        $this.glide.on('run.after', function(o) {
            $this.deepAR.switchEffect(0, 'slot', $this.effects[$this.glide.index].path);
        })

        $this.glide.mount()

    }
}
</script>

<style lang="scss" scoped>
canvas.deepar { 
    border: 0px none; 
    background-color: black; 
    display: block; 
    margin: auto; 
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.glide__slide--active{
    transform: scale(1.5);
    div{
        border: 2px solid white;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            width: calc(100% + 12px);
            height: calc(100% + 12px);
            border: 2px solid white;
            border-radius: 50%;
            left: -6px;
            top: -6px;
        }
    }
}

.bg-to-dark{
    background: linear-gradient(to bottom, rgba(black, 0), black);
}
</style>