import { render, staticRenderFns } from "./game.vue?vue&type=template&id=16eafb9e&scoped=true&"
import script from "./game.vue?vue&type=script&lang=js&"
export * from "./game.vue?vue&type=script&lang=js&"
import style0 from "./game.vue?vue&type=style&index=0&id=16eafb9e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16eafb9e",
  null
  
)

export default component.exports