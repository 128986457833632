<template>
    <div class="h-full w-full bg-light text-white flex flex-col items-center justify-center">
        
        <h1 data-aos="fade-up" class="font-montserrat text-3xl text-center uppercase mt-10 z-20 px-4 font-bold" v-html="$t('welcome')"></h1>
        <i data-aos="fade-down" data-aos-delay="300" class="fas fa-tree text-6xl mt-2"></i>
        
        <p class="px-10 text-center font-montserrat my-5">{{ $t('desc') }}</p>

        <nav data-aos="zoom-in" data-aos-delay="600" class="w-9/12 z-10">
            <button @click="start" class="rounded-full text-xl w-full shadow-2xl p-4 bg-black">{{ $t('start') }}</button>
        </nav>

        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-top h-72" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-top filter-white h-48" src="../../assets/images/triangle@2x.png" alt="">
        
        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-bottom h-72" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-bottom filter-white h-64" src="../../assets/images/triangle-b@2x.png" alt="">

    </div>
</template>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				welcome: 'Welcome to the <span class="text-primary text-2xl">ENVIRONMENTAL POLL</span>',
				desc: 'Picture your brand developing a sustainability campaign, you can engage with consumers by asking them their opinion or action.',
                start: "Let's Start"
			},
			de: { 
				welcome: 'Willkommen zur <span class="text-primary text-2xl">ökologischen Befragung</span>',
				desc: 'Stellen Sie sich vor, Ihre Marke entwickelt eine Nachhaltigkeitskampagne. Dank Connected Packaging können Sie mit den Verbrauchern in Kontakt treten, indem Sie sie nach ihren Meinungen und Aktivitäten fragen.',
                start: "Lassen Sie uns beginnen"
			},
			ru: { 
				welcome: 'Добро Пожаловать в <span class="text-primary text-2xl">Экологический Опрос</span>',
				desc: 'Представьте, что Ваш бренд разрабатывает кампанию по устойчивому развитию и Вы можете спросить мнение или действие своих потребителей',
                start: "Начать"
			}
		}
	},
    methods: {
        start(){
            this.$emit('upcomponent', 'poll.game')
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/poll/intro"
        })
    }
}
</script>

<style>

</style>