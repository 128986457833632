<template>
    <div class="h-full w-full bg-light text-white flex flex-col items-center justify-center">

        <img data-aos="zoom-in" data-aos-delay="600" src="../assets/images/logo-blue-stamp@2x.png" class="h-24" alt="">

        <p class="font-montserrat font-light text-sm my-4">{{ $t('thanks') }}</p>
        
        <h1 data-aos="fade-up" class="font-montserrat z-20 text-3xl text-center uppercase px-10 mt-5 font-bold">{{ $t('welcome') }}</h1>
        <p data-aos="fade-up" data-aos-delay="300" class="text-base font-montserrat uppercase text-center px-10 mt-2" v-html="$t('desc')"></p>

        <button data-aos="fade-down" data-aos-delay="900" @click="goTo('intro')" class="bg-dark rounded-full font-light px-12 mx-auto z-20 py-3 mt-6 text-white">{{ $t('start') }}</button>

        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000" class="circle-top h-72" src="../assets/images/circle@2x.png" alt="">
        <img class="triangle-top filter-white h-48" src="../assets/images/triangle@2x.png" alt="">
        
        <img data-aos="fade-up" data-aos-delay="1000" data-aos-duration="2000" class="circle-bottom h-72" src="../assets/images/circle@2x.png" alt="">
        <img class="triangle-bottom filter-white h-64" src="../assets/images/triangle-b@2x.png" alt="">

    </div>
</template>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				thanks: 'Thank you for scanning the QR Code',
                welcome: 'Welcome',
                desc: 'To the Tetra Pak Connected Package <br /> demo experience!',
                start: 'Start Here'
			},
			de: { 
				thanks: 'Vielen Dank für das Scannen des QR-Codes',
                welcome: 'Willkommen',
                desc: 'zur Tetra Pak Connected Package <br /> Demo Experience!',
                start: 'Starten Sie hier'
			},
			ru: { 
				thanks: 'Спасибо за сканирование QR кода',
                welcome: 'Добро пожаловать',
                desc: 'в Connected Package <br /> Demo Experience от Tetra Pak',
                start: 'Начать'
			}
		}
	},
    methods: {
        goTo(c){
            this.$emit('upcomponent', c)
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/welcome"
        })
    }
}
</script>