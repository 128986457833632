import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import { VuePlausible } from 'vue-plausible'

import './assets/scss/main.scss'

import AOS from 'aos';
import 'aos/dist/aos.css';

import axios from 'axios';

Vue.use(VueI18n)

Vue.use(VuePlausible, {
  domain: 'connectedpackage.digital',
  apiHost: 'https://analytics.appetitecreative.com',
  trackLocalhost: true
})
Vue.$plausible.enableAutoPageviews() 

Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      //baseURL: 'http://localhost:8000/api/v1/'
      baseURL: 'https://admin.connectedpackage.digital/api/v1/'
    })
  }
})

window.Cookies = require('js-cookie');
window.Shared = {
  user: {
    sessid: null
  }
}

AOS.init();

Vue.component('welcome', require('./pages/welcome').default);
Vue.component('intro', require('./pages/intro').default);
Vue.component('selfie', require('./pages/selfie').default);
Vue.component('selfie.preview', require('./pages/selfie/preview').default);
Vue.component('thanks', require('./pages/thanks').default);
Vue.component('finish', require('./pages/finish').default);
Vue.component('share', require('./pages/share').default);

Vue.component('leaderboard', require('./pages/leaderboard').default);

Vue.component('poll.intro', require('./pages/poll/index').default);
Vue.component('poll.game', require('./pages/poll/game').default);

Vue.component('quiz.intro', require('./pages/quiz/index').default);
Vue.component('quiz.play', require('./pages/quiz/play').default);
Vue.component('quiz.game', require('./pages/quiz/game').default);

Vue.component('game.intro', require('./pages/game').default);
Vue.component('game.play', require('./pages/game/play').default);

Vue.component('package.index', require('./pages/package/index').default);
Vue.component('package.intro', require('./pages/package/intro').default);

Vue.config.productionTip = false

function findGetParameter(parameterName) {
  var result = null,
      tmp = [];
  var items = location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

function getBrowserLanguage() {

  var userLang = navigator.language || navigator.userLanguage; 
  userLang = userLang.substring(0,2);

  var validLanguages = ['de','en','ru'];

  // force a language setting
  if(findGetParameter('lang')) return findGetParameter('lang');
  
  // or fetch user language
  if(validLanguages.includes(userLang)){
    return userLang;
  } else {
    return 'en';
  }

}

const i18n = new VueI18n({
  locale: getBrowserLanguage(),
  fallbackLocale: 'en'
})

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
