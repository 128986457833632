<template>
    <div class="py-4 h-full flex flex-col items-center text-white bg-b">
        
            <h1 v-if="result" key="h1result" data-aos="fade-up" class="font-montserrat text-3xl text-center uppercase pt-10 h-12 px-5 font-bold">{{ $t('thatsgreat') }}</h1>
            <h1 v-else key="h1noresult" data-aos="fade-up" class="font-montserrat text-2xl text-center uppercase pt-10 h-20 px-5 font-bold">{{ $t('prodinov') }}</h1>

            <div class="flex flex-col w-full justify-center items-center">
                
                <p v-if="result" class="font-normal font-montserrat w-10/12 h-10 text-center mt-10 mb-5">{{ $t('pplchoice') }}</p>
                <p v-else class="font-normal font-montserrat w-10/12 h-20 text-center mt-10 mb-5">{{ $t('newflav') }}</p>
                
                <nav class="flex flex-col mt-4 w-10/12">
                    <button @click="getResult" class="rounded-full focus:outline-none relative text-base px-4 w-full shadow-2xl h-16 flex justify-center items-center bg-primary">
                        <transition name="component-fade" mode="out-in">
                            <div v-if="result" class="bg-secondary text-primary w-10/12 flex items-center justify-center h-full mr-auto rounded-full -ml-4">
                                54%
                            </div>
                            <div v-else>
                                {{ $t('strawberry') }}
                            </div>
                        </transition>
                        <img src="../../assets/images/icon-strawberry@2x.png" class="h-24 absolute left-0 -mx-6 -mt-8">
                    </button>
                    <button @click="getResult" class="rounded-full focus:outline-none relative text-base px-4 my-4 w-full shadow-2xl h-16 flex justify-center items-center bg-primary">
                        <transition name="component-fade" mode="out-in">
                            <div v-if="result" class="bg-secondary text-primary w-7/12 flex items-center justify-center h-full mr-auto rounded-full -ml-6">
                                28%
                            </div>
                            <div v-else>
                                {{ $t('banana') }}
                            </div>
                        </transition>
                        <img src="../../assets/images/icon-lemon@2x.png" class="h-24 absolute right-0 -mr-8 -mt-8">
                    </button>
                    <button @click="getResult" class="rounded-full focus:outline-none relative text-base px-4 w-full shadow-2xl h-16 flex justify-center items-center bg-primary">
                        <transition name="component-fade" mode="out-in">
                            <div v-if="result" class="bg-secondary text-primary w-5/12 flex items-center justify-center h-full mr-auto rounded-full -ml-4">
                                18%
                            </div>
                            <div v-else>
                                {{ $t('watermelon') }}
                            </div>
                        </transition>
                        <img src="../../assets/images/icon-watermelon@2x.png" class="h-24 absolute left-0 -mx-8 -mt-4">
                    </button>
                </nav>
                <button v-if="result" data-aos="fade-down" @click="showThanks=true" class="bg-dark rounded-full font-light px-12 mx-auto z-20 py-3 mt-6 text-white">{{ $t('continue') }}</button>
            </div>

            <transition name="component-fade" mode="out-in">
                <thanks v-if="showThanks" poll="poll-b" @upcomponent="goTo" />
            </transition>
        

    </div>
</template>

<script>
import thanks from '../../components/thanks'

export default {
	i18n: {
		messages: {
			en: { 
				thatsgreat: "THAT’S GREAT!",
                prodinov: "Product Innovation",
                pplchoice: 'See what other people chose!',
                newflav: 'We would like to develop a fantastic new flavour for you! Which one would you be more interested in?',
                strawberry: 'Wild Strawberry',
                banana: 'Extreme Lemon',
                watermelon: 'Fresh Watermelon',
                continue: 'Continue'
			},
			de: { 
				thatsgreat: "GROSSARTIG!",
                prodinov: "Produkt-Innovation",
                pplchoice: 'Sehen Sie hier, was andere Leute gewählt haben!',
                newflav: 'Wir würden gerne eine neue Geschmacksrichtung für Sie entwickeln! Welche würde Sie am meisten ansprechen?',
                strawberry: 'Wild-Erdbeere',
                banana: 'Super-Zitrone',
                watermelon: 'Frische Wassermelone',
                continue: 'Weiter'
			},
			ru: { 
				thatsgreat: "Замечательно!",
                prodinov: "Продуктовые Инновации",
                pplchoice: 'Посмотреть, что выбрали другие',
                newflav: 'Мы хотим создать для Вас новый фантастический вкус! Какой из нижеперечисленных наиболее заинтересует Вас?',
                strawberry: 'Дикая Клубника',
                banana: 'Кислый Лимон',
                watermelon: 'Свежий Арбуз',
                continue: 'Продолжить'
			}
		}
	},
    data(){
        return{
            result: false,
            showThanks: false
        }
    },
    components: {
        thanks
    },
    methods: {
        next(){
            this.screen = 2
            this.className = 'bg-b'
        },
        getResult(){
            this.result = true
        },
        goTo(c){
            this.$emit('upcomponent', 'thanks', { poll: 'poll-b' })
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-a{
    background-color: #8DC63F;
    background-image: url(../../assets/images/bg-quiz-a@2x.png);
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.bg-b{
    background-color: #FABB2E;
    background-image: url(../../assets/images/bg-quiz-b@2x.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
</style>