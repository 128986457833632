<template>
    <div class="h-full bg-light w-full flex flex-col items-center justify-center">
        
        <h1 data-aos="fade-up" class="font-montserrat z-20 text-2xl text-white text-center uppercase px-10 my-5" v-html="$t('gorgeous')">

        </h1>

        <img :src="data.photo" class="border-primary border-4 w-5/12">

        <h1 class="text-white text-center mt-5 px-10">{{ $t('share') }}</h1>

        <nav data-aos="zoom-in" data-aos-delay="600" class="mt-5 flex z-10 mx-auto">
            <a @click="showCoupon=1" href="https://www.facebook.com/sharer/sharer.php?u=https://connectedpackage.digital/" target="_blank" class="bg-dark text-white h-10 w-10 text-xl flex justify-center items-center rounded-full"><i class="fab fa-facebook-f"></i></a>
            <a @click="showCoupon=1" href="http://www.twitter.com/share?url=https://connectedpackage.digital/" target="_blank" class="bg-dark text-white mx-1 h-10 w-10 text-xl flex justify-center items-center rounded-full"><i class="fab fa-twitter"></i></a>
            <a @click="showCoupon=1" href="whatsapp://send?text=Tetrapak Demo Experience https://connectedpackage.digital/" class="bg-dark text-white h-10 w-10 text-xl flex justify-center items-center rounded-full"><i class="fab fa-whatsapp"></i></a>
        </nav>

        <transition name="component-fade" mode="out-in">
            <coupon v-if="showCoupon" poll="poll-b" @upcomponent="goTo" />
        </transition>

    </div>
</template>

<script>
import coupon from '../../components/coupon'

export default {
    i18n: {
		messages: {
			en: { 
				gorgeous: 'You look... <span class="font-bold">gorgeous</span>!',
				share: 'Share with your friends and give them a redeemable discount!',
			},
			de: { 
				gorgeous: 'Sie sehen <span class="font-bold">GROSSARTIG</span> aus!',
				share: 'Teilen Sie es mit Ihren Freunden um ihnen einen einlösbaren Rabatt zu sichern!',
			},
			ru: { 
				gorgeous: 'ты выглядишь <span class="font-bold">РОСКОШНО</span>!',
				share: 'Поделитесь с друзьями и дайте им скидку!',
			}
		}
	},
    props: ['data'],
    methods: {
        goTo(c){
            this.$emit('upcomponent', 'intro')
        }
    },
    data(){
        return{
            showCoupon: false
        }
    },
    components: {
        coupon
    }
}
</script>