<template>
    <div class="overlay">

        <div class="w-10/12 rounded-lg text-center py-12 px-6 mx-auto poll-b">
            <h1 class="font-montserrat font-bold text-3xl uppercase">Thank You</h1>
            
            <p class="font-montserrat py-5">Here's your code:</p>
            <p class="font-montserrat py-5 font-bold text-xl">ABCDEF</p>

            <button @click="goTo" class="bg-dark rounded-full font-light px-12 mx-auto z-20 py-3 mt-6 text-white">Continue</button>
        </div>

    </div>
</template>

<script>
export default {
    props: ['poll'],
    methods: {
        goTo(){
            this.$emit('upcomponent')
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay{
    background: rgba(0,0,0,.8);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 123;
    display: flex;
    justify-content: center;
    align-items: center;
    .poll-a{
        background-image: url(../assets/images/bg-thanks-a@2x.png);
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
    }
    .poll-b{
        background-image: url(../assets/images/bg-thanks-b@2x.png);
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
    }
    .poll-c{
        background-image: url(../assets/images/bg-thanks-a@2x.png);
        background-size: 100%;
        background-position: center;
        background-repeat: repeat;
        color: white;
    }
}
</style>