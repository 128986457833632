<template>
    <div class="h-full w-full bg-light text-white flex flex-col items-center justify-center">
        
        <h1 data-aos="fade-up" class="font-montserrat text-3xl text-center uppercase mt-10 z-20 px-4 font-bold" v-html="$t('title')"></h1>
        <i data-aos="fade-down" data-aos-delay="300" class="fas fa-lightbulb text-6xl mt-2"></i>
        
        <p class="px-10 text-center font-montserrat my-5">{{ $t('desc') }}</p>

        <nav data-aos="zoom-in" data-aos-delay="600" class="w-9/12 z-10">
            <button @click="start" class="rounded-full text-xl w-full shadow-2xl p-4 bg-black">{{ $t('start') }}</button>
        </nav>

        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-top h-72" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-top filter-white h-48" src="../../assets/images/triangle@2x.png" alt="">
        
        <img data-aos="fade-up" data-aos-delay="500" data-aos-duration="500" class="circle-bottom h-72" src="../../assets/images/circle@2x.png" alt="">
        <img class="triangle-bottom filter-white h-64" src="../../assets/images/triangle-b@2x.png" alt="">

    </div>
</template>

<script>
export default {
	i18n: {
		messages: {
			en: { 
				title: 'Welcome to the <span class="text-primary text-2xl">INNOVATION POLL</span>',
                desc: 'Picture your brand developing an innovation campaign, you can engage with consumers by asking them their opinion or by triggering actions.',
                start: "Let's Start"
			},
			de: { 
                title: 'Willkommen bei der <span class="text-primary text-xl">zum Thema Produktinnovationen</span>',
				desc: 'Stellen Sie sich vor, Ihre Marke entwickelt eine Innovationskampagne. Durch Connected Packaging können Sie mit den Konsumenten in Kontakt treten, indem Sie sie nach ihren Meinungen oder Aktivitäten fragen.',
                start: 'Lassen Sie uns beginnen'
			},
			ru: { 
                title: 'Добро пожаловать в <span class="text-primary text-2xl">Инновационный Опрос</span>',
				desc: 'Представьте, что Ваш бренд разрабатывает инновационную кампанию и Вы можете спросить мнение или действие своих потребителей',
                start: 'Начать'
			}
		}
	},
    methods: {
        start(){
            this.$emit('upcomponent', 'quiz.game')
        }
    },
    mounted(){
        this.$plausible.trackPageview({
            url: "https://connectedpackage.digital/quiz/intro"
        })
    }
}
</script>

<style>

</style>